<template>
  <div>
    <!-- 头部 -->
    <section class="section panel header_title_box">
      <div class="col-lg-12 container_max container">
        <div class="col-lg-12 flex-wb wow animate__fadeInUp">
          <div class="T48px_Regular col-lg-5 colorfff left_text_box">
            <span class="color1D73FF">{{ $t("case.title1") }}</span>
            <span>{{ $t("case.title2") }}</span>
          </div>
          <div class="T24px_Regular_ya col-lg-6 colorfff rigth_text_box">
            {{ $t("case.rigth_text_box") }}
          </div>
        </div>

        <div class="header_menu_box col-lg-12">
          <button class="menu_btn T20px_Regular colorfff act animation_right">
            {{ $t("case.menu_btn1") }}
          </button>
          <button class="menu_btn T20px_Regular colorfff animation_right">
            {{ $t("case.menu_btn2") }}
          </button>
        </div>

        <div class="project_list col-lg-12">
          <div
            class="project_item"
            v-for="(item, index) in projecList"
            @click="routeUrlBtn(item.routeUrl)"
            :key="index"
          >
            <div class="project_logo_box">
              <img :src="item.url" class="project_logo" alt="" />
            </div>
            <div class="info_box flex-c">
              <div class="left_info_box flex-w">
                <span class="T36px_Regular color1D73FF"> {{ item.name }}</span>
                <span class="geli"></span>
                <span class="T20px_Regular colorfff left_info_box_tips"> {{ item.tips }}</span>
              </div>
              <div class="rigth_info_box">
                <span
                  class="child_item_btn T20px_Regular colorfff"
                  v-for="(items, indexs) in item.child"
                  :key="indexs"
                >
                  {{ items }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
      projecList: [
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/mx.png",
          name: this.$t("case.projecList1_name"),
          tips: this.$t("case.projecList1_tips"),
          child: [
            this.$t("case.projecList1_child1"),
            this.$t("case.projecList1_child1"),
          ],
          routeUrl: "/moXun",
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/shj.png",
          name: this.$t("case.projecList2_name"),
          tips: this.$t("case.projecList2_tips"),
          child: [
            this.$t("case.projecList2_child1"),
            this.$t("case.projecList2_child1"),
          ],
          routeUrl: "/sanhaiJing",
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/xygl.png",
          name: this.$t("case.projecList3_name"),
          tips: this.$t("case.projecList3_tips"),
          child: [
            this.$t("case.projecList3_child1"),
            this.$t("case.projecList3_child1"),
          ],
          routeUrl: "/college",
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/rz.png",
          name: this.$t("case.projecList4_name"),
          tips: this.$t("case.projecList4_tips"),
          child: [
            this.$t("case.projecList4_child1"),
            this.$t("case.projecList4_child1"),
          ],
          routeUrl: "/ninja",
        },
      ],
    };
  },
  mounted() {
    const wow = new WOW();
    wow.init();
  },
  methods: {
    /**
     * 跳转项目路由
     * @param {*} url 跳转相对路由
     */
    routeUrlBtn(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.header_title_box {
  min-height: 100vh;
  padding-top: 5.6875rem;
  padding-bottom: 2rem;
  background: #15171d;

  .container_max {
    // width: 100vw;
    // padding: 0 5.8438rem;

    position: relative;

    .left_text_box {
      width: 16.0313rem;
      min-height: 3.875rem;
    }

    .rigth_text_box {
      margin-top: 0.625rem;
      // width: 21.375rem;
      line-height: 1.488rem;
    }

    .header_menu_box {
      margin-top: 1.75rem;
      padding-bottom: 0.5313rem;
      border-bottom: 0.0313rem solid #ffffff;

      .menu_btn {
        border: 0.0313rem solid #ffffff;
        border-radius: 1.75rem;
        padding: 0.2188rem 1rem;
        background: transparent;
        margin-left: 1.4063rem;
        position: relative;
        z-index: 1;
        overflow: hidden;

        &:after {
          border-radius: 1.75rem;
        }
        &:nth-child(1){
          margin: 0;
        }
      }

      .act {
        background: #1d73ff;
        border: 0.0313rem solid #1d73ff;
      }
    }

    .project_list {
      .project_item {
        padding-top: 1.075rem;
        // width: 34.7188rem;
        overflow: hidden;

        &:hover {
          .project_logo {
            transform: scale(1.05);
          }
        }

        .project_logo_box {
          width: 100%;
          height: 11.3438rem;
          overflow: hidden;

          .project_logo {
            width: 100%;
            height: 100%;
            transition: all 0.8s;
            // object-fit: contain;
          }
        }

        .info_box {
          margin-top: 0.5313rem;

          .geli {
            display: inline-block;
            width: 0.25rem;
            height: 0;
            border: 0.0625rem solid #ffffff;
            margin: 0 0.5625rem;
          }
        }
        .left_info_box{
          // flex-wrap: nowrap;
          overflow: hidden;
          // .left_info_box_tips{
          //   width: 13rem;
          // }
        }

        .rigth_info_box {
          .child_item_btn {
            padding: 0.1188rem 0.5rem;
            border: 0.0313rem solid #ffffff;
            border-radius: 0.75rem;
            margin-right: 0.6875rem;

            &:nth-child(0) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
